import { Component, ElementRef } from '@angular/core';
import { Router, Event, NavigationEnd } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { EligibilityService } from "./shared/services/eligibility.service";
import { AppService } from "./app.service";
import { IConfig } from "./models/config";
import { ProfileSelectionService } from "./shared/services/profile-selection.service";
import { switchMap } from "rxjs/operators";
import { AuthenticationService } from './shared/services/authentication.service';
import { of } from 'rxjs/internal/observable/of';
import { HTTP_STATUS_CODE } from './shared/constants/app.constants';

declare function loadCustomerSelectionJS(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Verisk Excess &amp; Reinsurance';
  appConfig: IConfig;

  errorMessage: any;
  customerSupportUrl: string;
  deepLinkURL: string = '';

  isAuthenticated = false;
  isEligible: boolean = false;
  isLoading: boolean = true;
  token: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    private cookieService: CookieService,
    private eligibilityService: EligibilityService,
    private profileSelectionService: ProfileSelectionService,
    private elementRef: ElementRef
  ) {

    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  setAuthConfig() {
    this.appService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          this.appConfig = this.appService.getGlobalConfig();
          this.customerSupportUrl = this.appConfig.veriskCustomerSupport;
          this.setAuthStatus();
        }
      },
      error => (this.errorMessage = error as any)
    );
  }

  setAuthStatus() {
    this.authenticationService.getLoginStatus().subscribe(status => {
      this.isAuthenticated = status;
      if (this.isAuthenticated && this.appConfig.loadCustomerSelection && this.appConfig.loadCustomerSelection != "") {
        this.setCoookieToken();

        if (!document.querySelector('script[src="' + this.appConfig.loadCustomerSelection + '"]')) {
          let id = "customer_selection_switch";
          let element = document.getElementById(id);
          if (!(element)) {
            let s = document.createElement("script");
            s.type = "text/javascript";
            s.src = this.appConfig.loadCustomerSelection;
            this.elementRef.nativeElement.appendChild(s);
            setTimeout(() => {
              this.profileSelectionService.setProductContextCookie();
              loadCustomerSelectionJS();
            }, 2000);
          }
        }
      }
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    this.errorMessage = "";
    if (routerEvent instanceof NavigationEnd) {
      this.setCoookieToken();
      if (this.token && this.token != '') {
        this.authenticationService.setLoginStatus(true);
        this.appService.getConfigStatus().subscribe(_configStatus => {
          if (_configStatus) {
            let _appConfig = this.appService.getGlobalConfig();
            this.profileSelectionService.setProductContextCookie();
            this.profileSelectionService.hasCompanyAccess().pipe(
              switchMap(hasAccess => {
                if (hasAccess === false) {
                  this.isEligible = false;
                  this.isLoading = false;
                  this.createDeepLinkUrl();
                  this.router.navigate(['/unauthorized']);

                  return of(false); // If the user doesn't have company access, return an observable that completes immediately
                }
                return this.profileSelectionService.includeIdealUX();
              }),
              switchMap((hasCompanyAccess) => {
                if (hasCompanyAccess === false) {
                  this.router.navigate(['/unauthorized']);
                  return of(false);
                }
                this.eligibilityService
                  .GetEligibleProducts(_appConfig.apiUrl)
                  .subscribe((products) => {
                    if (products.filter(s => s.isEligible === true).length > 0) {
                      this.eligibilityService.setEligibility(products);

                      if (location.href.indexOf("unauthorized") > 0) {
                        this.router.navigate(['/']);
                      }
                    }
                    else {
                      this.router.navigate(['/unauthorized']);
                    }
                    return of(true);
                  },
                    (error) => {
                      if (error.status === HTTP_STATUS_CODE.NOT_FOUND ||
                        error.status === HTTP_STATUS_CODE.BAD_REQUEST) {
                        this.router.navigate(['/unauthorized']);
                      }
                      return of(false);
                    });
              })
            )
              .subscribe(
                result => {
                  this.isEligible = result;
                  this.isLoading = false;

                  this.createDeepLinkUrl();
                },
                error => {
                  this.errorMessage = error;
                  this.isLoading = false;
                }
              );
            this.setAuthConfig();
          }
        });
      }
    }
  }

  createDeepLinkUrl() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // Remove the redirectedFromMenu query parameter
    params.delete('redirectedFromMenu');

    // Update the URL with the modified query parameters
    url.search = params.toString();
    this.deepLinkURL = url.toString();
  }

  setCoookieToken() {
    let oktaStorage = localStorage.getItem("okta-token-storage");
    let token = JSON.parse(oktaStorage)?.accessToken?.accessToken;
    if (token) {
      this.cookieService.set("token", token, null, '/', '', true);
    }
    this.token = token;
  }
}
