import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let headers = null;
    let localStorageToken = localStorage.getItem("okta-token-storage");

    if(localStorageToken !== '{}') {
      let token = JSON.parse(localStorageToken)?.accessToken?.accessToken;

      headers = req.headers.set('Authorization', `Bearer ${token}`);
    }

    const jsonReq: HttpRequest<any> = req.clone({ headers });

    return next.handle(jsonReq);
  }
}
