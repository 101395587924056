<footer class="v-footer p-3">
	<div class="container-fluid d-flex flex-column flex-lg-row">
		<div class="item-border order-0"></div>
		<div class="item-center order-2 order-lg-1 text-center">
			<p class="text-white m-0">
				Copyright &copy; {{currentYear}} Insurance Services Office, Inc. All rights reserved.
			</p>
			<div class="d-inline-flex gap-3 flex-wrap">
				<a href="{{footerLinks.corporateHome}}">Verisk Corporate Home</a> <span>|</span>
				<a href="{{footerLinks.termsAndConditions}}">Terms and Conditions</a> <span>|</span>
				<a href="{{footerLinks.privacyAndPolicy}}">Privacy and Security Policy</a> <span>|</span>
				<a href="{{footerLinks.contactUs}}">Contact Us</a>
			</div>
		</div>
		<div class="item-border order-1 order-lg-2 text-center align-self-center mb-3 mb-lg-0">
			<img src="../../../assets/img/Verisk_24_KO_footer.png" height="24px" alt="footer_img" />
		</div>
	</div>
</footer>
