import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { IConfig } from 'src/app/models/config';
import { EligibilityService } from 'src/app/shared/services/eligibility.service';
import { FileManagerService } from 'src/app/shared/services/file-manager/file-manager.service';

@Component({
  selector: 'app-excess-and-reinsurance',
  templateUrl: './excess-and-reinsurance.component.html',
  styleUrls: ['./excess-and-reinsurance.component.css']
})

export class ExcessAndReinsuranceComponent implements OnInit {
  appConfig: IConfig;
  fileMetaData: any;
  products: any;
  deepLinkURL: string = '';

  constructor(private router: Router,
    private appService: AppService,
    private fileManagerService: FileManagerService,
    private eligibilityService: EligibilityService
  ) {  }
  
  ngOnInit(): void {
    this.eligibilityService.loadEligibility();

    this.eligibilityService.eligibility$.subscribe(data => {
      this.products = data;
    });

    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
        this.deepLinkURL = window.location.href;
      }
    });
  }

  loadFileDetails(reportName: any) {
    if (reportName.isEligible) {

      this.fileManagerService
        .getProductPresignedUrl(this.appConfig.apiUrl, reportName.name)
        .subscribe((fileResponse: any) => {

          let fileMetadata = {
            preSignedUrl: fileResponse.preSignedUrl,
            fileName: fileResponse.file,
            title: `Download the ${fileResponse.file}`
          }

          if (fileResponse.file != null && fileResponse.file != '') {
            localStorage.setItem('fileDetails', null);
            localStorage.setItem('fileDetails', JSON.stringify(fileMetadata));
            const link = this.router.serializeUrl(this.router.createUrlTree(['download']));
            window.open(`${window.location.protocol}//${window.location.host}${link}`, '_blank');
          }
        });
    }
  }
}
