import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { MenuResponse } from 'src/app/models/menu.model';
import { AppService } from 'src/app/app.service';
import { IConfig } from 'src/app/models/config';
import { USER_PROFILE_FILTER_ARGS } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  menuItems: MenuResponse; // megamenu data set
  hover_megamenu: string = 'allprod'
  appConfig: IConfig;
  customerName: string = "No Company";
  homePageApiUrl: string = "";
  userProfileFilterArgs = USER_PROFILE_FILTER_ARGS;

  constructor(private headerService: HeaderService,
    private appService: AppService
  ) { }

  ngOnInit(): void {

    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
        this.homePageApiUrl = this.appConfig.homePageApiUrl;
      }
    });

    let customerName = localStorage.getItem("customername");

    if (customerName !== undefined && customerName !== "" && customerName !== null) {
      this.customerName = customerName;
    }

    this.headerService.getMenuItems().subscribe((data: MenuResponse) => {
      this.menuItems = data;
      if (data.userProducts && data.userProducts.length > 0) {
        this.hover_megamenu = 'myprod';
      }
    });
  }


  hrefOpen(url) {
    window.open(url, "_blank")
  }

  hoverIn(category: string) {
    this.hover_megamenu = category;
  }

}
