<div class="container mt-5">
    <table class="w-100">
        <tr>
            <td>
            <h2>ISOnet Customer Notice</h2>
            <br/>
            <i><b>Product Description:&nbsp;</b></i>Excess and Reinsurance Suite<br/>		
            <br/>We're sorry, but your ISOnet user ID is not authorized for access to Excess and Reinsurance Suite.<br/>
            <br/>
            For information on how you can subscribe to access Excess and Reinsurance Suite, please contact Verisk Customer Support or ISO Regional Sales Office: <br/>
            <br/>
            <table class="w-100">
                <tr>
                <td><b>Verisk Customer Support:</b> <br/>Phone: 1-800-888-4476&nbsp;&nbsp;<br/>
                    E-mail:	<a href="mailto:isonet@verisk.com"> isonet@verisk.com</a>
                </td>
                <td>
                    <b>ISO Regional Sales Offices:</b> <br/>Contact information: <a href="{{customerSuportUrl}}" >click here </a>
                </td>
                </tr>
            </table>
            </td>
        </tr>
    </table>
  <input type="hidden" name="deepLink" id="deepLink" value="{{deepLinkURL}}" />
</div>