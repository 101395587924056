import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component'
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';
import { ExcessAndReinsuranceComponent } from './components/excess-and-reinsurance/excess-and-reinsurance.component';
import { FlatFileComponentComponent } from './components/flat-file-component/flat-file-component.component';
import { AppService } from './app.service';
import { VrskAuthOktaModule, VRSK_AUTH_OKTA_CONFIG } from '@uw-verisk/auth-okta';
import { AddHeaderInterceptor } from './core/add-header.interceptor';
import { ProfileSelectionInterceptor } from './core/profile-selection.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './shared/services/authentication.service';
import { HeaderService } from './components/header/header.service';
import { UserProfileFilterPipe } from './shared/pipes/userprofilefilter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UnauthorizeComponent,
    ExcessAndReinsuranceComponent,
    FlatFileComponentComponent,
    UserProfileFilterPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    VrskAuthOktaModule,
    RouterModule.forRoot([]),
  ],
  
  providers: [
    AppService,
    CookieService,
    AuthenticationService,
    HeaderService,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: AddHeaderInterceptor,
      multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProfileSelectionInterceptor,
      multi: true,
      deps: [CookieService]
    },
    {
      provide: VRSK_AUTH_OKTA_CONFIG,
      useValue: '../assets/jsons/config.json'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
