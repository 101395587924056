import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
    selector: 'unauthorize',
    templateUrl: './unauthorize.component.html'
  })
  
  export class UnauthorizeComponent implements OnInit {
    customerSuportUrl: string;
    deepLinkURL: string = '';

    constructor(private appService: AppService) {
        this.appService.getConfigStatus().subscribe(
            _configStatus => {
              if(_configStatus) {
                let _appConfig = this.appService.getGlobalConfig();
                this.customerSuportUrl = _appConfig.veriskCustomerSupport;
              }
        });
    }
  ngOnInit(): void {
    this.deepLinkURL = window.location.href;
  }
}