import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flat-file-component',
  templateUrl: './flat-file-component.component.html',
  styleUrls: ['./flat-file-component.component.css']
})
export class FlatFileComponentComponent implements OnInit {
  fileDetails : any;
  staticText : string;
  progress = 0;
  errorMessage: string;
  deepLinkURL: string = '';


  ngOnInit(): void {
    this.deepLinkURL = window.location.href;

    var data = JSON.parse(localStorage.getItem('fileDetails') || '[]');
    
    if(data && data.length < 1) {
      window.location.href = "/";
    }

    localStorage.setItem("fileDetails","");

    if(data) {
      this.fileDetails = data;
      if(this.fileDetails.folder =="MILD") {
        this.staticText = `With the Multi-Distributional Increased Limits Developer (MILD),you can 
          calculate increased limits factors and price entire reinsurance treaties quickly, easily, 
          and efficiently. MILD saves time and expense, and supplies answers more closely related 
          to the business being re-insured.`;
      }
      else if(this.fileDetails.folder == "ALAE") {
        this.staticText = `The size-of-Loss joint ALAE/Indemnity database allows users to access our 
          very detailed size-of-loss distributions for Indemnity, ALAE, or the convolution of the two. 
          This database allows the users to customize, and/or combine, certain standard ISO casuality 
          size-of-loss distributions for their own purposes,benchmarks, and pricing models.`;
      }
      else if(this.fileDetails.folder == "HOII") {
        this.staticText = `The HomeOwners Section II Layer-of-Losss Data contains losses reported under 
          the homeowners module of the personal Lines statistical Plan - Other Than Auto(PLSPOTA).`;
      }
    }
    else {
      this.errorMessage = "No files to download"
    }
  }

  downloadFile(data : any) {
    window.open(data.preSignedUrl,"_blank");
  }
  
}
