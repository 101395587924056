import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root', // root level, angular creates a single shared instance of this service
})

export class AuthenticationService {

  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.isAuthenticated = new BehaviorSubject(false);
  }

  getLoginStatus(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  setLoginStatus(status: boolean): void {
    this.isAuthenticated.next(status);
  }
}