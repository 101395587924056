import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { MenuResponse } from '../../models/menu.model';
import { IConfig } from '../../models/config';

@Injectable()
export class HeaderService {

  private menuItems = new BehaviorSubject<MenuResponse>(new MenuResponse());
  menu$ = this.menuItems.asObservable();
  appConfig: IConfig;

  constructor(private http: HttpClient, private appService: AppService) {
    this.appService
      .getConfigStatus()
      .subscribe(
        configStatus => {
          if (configStatus) {
            this.appConfig = this.appService.getGlobalConfig();
            this.getMenu(this.appConfig.apiUrl).subscribe((data: MenuResponse) => {
              this.menuItems.next(data);
            });
          }
        });
  }

  getMenu(apiUrl: string): Observable<MenuResponse> {
    return this.http.get<MenuResponse>(`${apiUrl}/Menu/HeaderMenu`);
  }

  getMenuItems(): Observable<MenuResponse> {
    return this.menu$;
  }
}
