import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { LOCAL_STORAGE_ELIGIBLE_PRODUCTS } from "../constants/app.constants";

@Injectable({
  providedIn: "root"
})
export class EligibilityService {

  private eligibilitySubject = new BehaviorSubject<any[]>([]);
  eligibility$ = this.eligibilitySubject.asObservable();

  constructor(private http: HttpClient) {
    window.addEventListener('storage', this.onStorageChange.bind(this));
  }

  private onStorageChange(event: StorageEvent) {
    if (event.key === LOCAL_STORAGE_ELIGIBLE_PRODUCTS && event.newValue) {
      const updatedData = JSON.parse(event.newValue);
      this.eligibilitySubject.next(updatedData);
      window.location.href = "/";
    }
  }

  GetEligibleProducts(path: string): Observable<any> {
    const url = `${path}/User/GetEligibleProducts`;
    return this.http.get(url).pipe(tap());
  }

  setEligibility(data: any[]) {
    this.eligibilitySubject.next(data);
    localStorage.setItem(LOCAL_STORAGE_ELIGIBLE_PRODUCTS, JSON.stringify(data));
  }

  loadEligibility() {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ELIGIBLE_PRODUCTS) || '[]');
    this.eligibilitySubject.next(data);
  }
}
