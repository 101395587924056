<div class="container mt-4">
  <div class="d-lg-flex justify-content-between mb-4">
    <div class="staticText mb-5">
      Verisk Excess and Reinsurance provides a wide range of solutions to assist Reinsures,
      Excess and surples carries, and primary carriers with pricing and reserving for both
      property and casualty lines of business.
    </div>
    <div class="text-center mb-5">
      <span class="staticText mb-5"> Looking for ISO Market Watch ? </span>
      <div>
        <span class="me-2">Visit</span>
        <a class="text-decoration-none" href="{{ this.appConfig?.marketWatchUrl }}" target="_blank">
          <img class="me-2" src="../../../assets/img/excess_reinsurance_blue.png" width="14" alt="product logo" />
          <span class="product-tile-apptitle text-center">MarketWatch</span>
        </a>
      </div>
    </div>
  </div>

  <div class="d-grid mb-5 " id="prd_grid">
    <div *ngFor="let product of products" class="product-tile p-4" (click)="loadFileDetails(product)">
      <!--Product logo-->
      <div *ngIf="product.isEligible" class="text-center mb-4">
        <img src="../../../assets/img/excess_reinsurance_blue.png" width="50" alt="product logo" />
      </div>

      <div *ngIf="!product.isEligible" class="text-center mb-4">
        <img src="../../../assets/img/excess_reinsurance_gray.png" width="50" alt="product logo" />
      </div>
      <!--Product title-->
      <div class="product-tile-apptitle text-center">{{ product.name }}</div>
    </div>
  </div>
  <input type="hidden" name="deepLink" id="deepLink" value="{{deepLinkURL}}" />

</div>
