import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlingService } from '../error-handling/error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  constructor(private http: HttpClient,
    private errorHandlingService: ErrorHandlingService
  ) { }

  getProductPresignedUrl(url: string, productName: string): Observable<any> {
    return this.http.get<any>(`${url}/S3/GetPresignedUrl?productName=${productName}`)
      .pipe(
        catchError(this.errorHandlingService.handleError)
      );
  }

  downloadFile(url: string): Observable<HttpEvent<any>> {
    return this.http.get(`${url}`,
      {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
        headers: { accept: 'application/octet-stream' }
      });
  }
}
