<header>

	<div class="header-ei">
		<div class="container-fluid d-flex gap-3 align-items-center py-2">
			<ul class="nav navbar-nav">
				<li class="mega-menu-dropdown dropdown" aria-controls="nav" aria-expanded="false"
					aria-label="Toggle navigation">
					<a href="#nav" data-bs-toggle="dropdown" role="button">
						<i class="material-icons">menu</i>
					</a>
					<div id="nav" class="dropdown-menu mega-menu-drop mega-menu2" style="left:0; margin-top:7px">
						<div class="left-nav">
							<ul>
								<li class="nav top-link special-link"
									[ngClass]="hover_megamenu == 'myprod' ? 'active' : ''" id="item1"
									(mouseenter)="hoverIn('myprod')"><a href="javascript:;">My Products</a></li>
								<li class="nav top-link special-link"
									[ngClass]="hover_megamenu == 'allprod' ? 'active' : ''" id="item2"
									(mouseenter)="hoverIn('allprod')"><a href="javascript:;">Full List of Products</a>
								</li>

								<div class="prod-cat">Product Categories</div>

								<li class="nav" [ngClass]="hover_megamenu == category.title ? 'active' : ''"
									id="item{{ i + 3 }}" (mouseenter)="hoverIn(category.title)"
									*ngFor="let category of menuItems.categories; let i = index">
									<a href="javascript:;" class="indent">{{ category.title }}</a>
								</li>
							</ul>
						</div>

						<div class="content-sub-nav" id="user-product-list"
							[ngClass]="hover_megamenu == 'myprod' ? '' : 'd-none'">
							<ul class="sub-menu">
								<li *ngFor="let product of menuItems.userProducts">
									<a href="{{ product.url }}" (click)="hrefOpen(product.url)"
										target="_blank">{{product.title}}</a>
								</li>
							</ul>
						</div>

						<div class="content-sub-nav" id="full-product-list"
							[ngClass]="hover_megamenu == 'allprod' ? '' : 'd-none'">
							<ul class="sub-menu">
								<li *ngFor="let product of menuItems.products">
									<a *ngIf="product.title != 'Electronic Participation Reports'"
										href="{{ product.url }}" (click)="hrefOpen(product.url)"
										[ngClass]="product.isEligible ? '' : 'normal'"
										target="_blank">{{product.title}}</a>
								</li>
							</ul>
						</div>

						<ng-template ngFor let-cat [ngForOf]="menuItems.categories">
							<div class="content-sub-nav" id="content_3"
								[ngClass]="{'d-none': cat.title != hover_megamenu}">
								<ul class="sub-menu">
									<li *ngFor="let product of cat.products">
										<a class="product-link" [ngClass]="product.isEligible ? '' : 'normal'"
											href="{{product.url}}" target="_blank">{{product.title}}</a>
									</li>
								</ul>
							</div>
						</ng-template>
					</div>
				</li>
			</ul>
			<div>
				<a class="logo" href="{{ homePageApiUrl }}" alt="ISO Logo"></a>
			</div>
			<div class="ms-auto">
				<div class="tools pull-right">
					<div class="user-tools">
						<div class="dropdown">
							<a href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true"
								aria-expanded="false" class="dropdown-toggle d-flex align-items-center me-2">
								<i class="material-icons">person</i>
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="dropdownMenu1">
								<li>
									<h3 class="dropdown-item p-2" style="color: #333;">{{ customerName }}</h3>
									<hr />
								</li>
								<li id="switch-profile" onclick="openCustomerSelectionModal()" style="display: none;">
									<a class="dropdown-item" href="javascript:void(0);">Switch Profile</a>
									<hr />
								</li>
								<li *ngFor="let userProfileLink of menuItems.userProfileLinks | userProfileFilter: userProfileFilterArgs">
									<a class="dropdown-item" href="{{userProfileLink.url}}" target="_blank">{{userProfileLink.linkFor}}</a>
								</li>
								<li><a class="dropdown-item" href="/auth/logout">Sign out</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="navbar-header product-nav">
		<div class="container-fluid">
			<div class="d-flex justify-content-between align-items-center">
				<label class="iso-Portal">
					<a class="text-decoration-none" routerLink="/">Excess and Reinsurance</a>
				</label>
			</div>
		</div>
	</div>

</header>
