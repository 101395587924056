<div class="container mt-4">
  <div class="staticText mb-5">
    {{staticText}}
  </div>

  <div class="alert alert-danger my-4" *ngIf="errorMessage">{{errorMessage}}</div>

  <div *ngIf="errorMessage !== 'No files to download' ">
    <h2 class="fs-5 p-0 mb-5">Report to Download</h2>

    <div class="d-flex align-items-center gap-4 file-holder" (click)="downloadFile(fileDetails)">
      <img src="../../../assets/img/MarketWatch_Download.png" width="50" alt="report" />
      {{fileDetails?.fileName}}
    </div>
  </div>
  <input type="hidden" name="deepLink" id="deepLink" value="{{deepLinkURL}}" />

</div>