import { Component } from '@angular/core';
import { MenuResponse } from 'src/app/models/menu.model';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  footerLinks = { corporateHome: '', termsAndConditions: '', privacyAndPolicy: '', contactUs: '' };
  errorMessage = '';
  lastHeight: number = 0;
  currentYear = new Date().getFullYear();

  constructor(
    private headerService: HeaderService) {
    this.headerService.getMenuItems().subscribe((data: MenuResponse) => {
      this.setUserProfile(data.userProfileLinks);
    });
  }

  setUserProfile(userProfileLinks) {
    userProfileLinks.forEach(item => {
      if (item.code === 'ISO_HOME') {
        this.footerLinks.corporateHome = item.url;
      }
      if (item.code === 'TERMS') {
        this.footerLinks.termsAndConditions = item.url;
      }
      if (item.code === 'POLICY') {
        this.footerLinks.privacyAndPolicy = item.url;
      }
      if (item.code === 'CONTACT') {
        this.footerLinks.contactUs = item.url;
      }
    });
  }

}
