import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { IConfig } from '../app/models/config';

@Injectable()
export class AppService {

    private globalConfig = '../assets/jsons/config.json';

    private appConfig: IConfig;
    private appConfigLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private http: HttpClient) {
        this.http.get<IConfig>(this.globalConfig).subscribe(
            _config => {
                this.appConfig = _config;
                this.appConfigLoaded.next(true);
            }
        )
    }

    getConfigStatus(): Observable<boolean> {
        return this.appConfigLoaded.asObservable()
    }

    getGlobalConfig(): IConfig {
        return this.appConfig;
    }
}
