import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExcessAndReinsuranceComponent } from './components/excess-and-reinsurance/excess-and-reinsurance.component';
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';
import { FlatFileComponentComponent } from './components/flat-file-component/flat-file-component.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { VrskAuthOktaLogoutComponent, VrskGatewayLoginGuard, VrskOktaLoginFlowComponent } from '@uw-verisk/auth-okta';

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [VrskGatewayLoginGuard],
    component: ExcessAndReinsuranceComponent
  },
  {
    path: 'auth',
    children: [
      {
        path: 'callback',
        component: OktaCallbackComponent
      },
      {
        path: 'oktaLoginFlow',
        component: VrskOktaLoginFlowComponent
      },
      {
        path: 'logout',
        component: VrskAuthOktaLogoutComponent
      },
      { path: 'logged-out', pathMatch: 'full', redirectTo: '/' }
    ]
  },
  { 
    path: 'unauthorized', 
    component: UnauthorizeComponent 
  },
  {
    path: 'dashboard',
    canActivate: [VrskGatewayLoginGuard],
    component: ExcessAndReinsuranceComponent
  },
  {
    path: 'download',
    canActivate: [VrskGatewayLoginGuard],
    component: FlatFileComponentComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
